<template>
	<div>
		<div class="img margin-b-sm" v-show="isReelect === false">
			<div v-if="pic !== ''">
				<el-image :src="pic"></el-image>
				<div class="reelect-img" @click="reelect">重新选择</div>
			</div>

			<div v-if="picArray.length > 0">
				<div v-for="(item, index) in picArray" :key="index">
					<el-image :src="imageUrl + item"></el-image>
					<div class="reelect-img" @click="reelect">重新选择</div>
				</div>
			</div>

			<div v-if="picArray.length === 0 && pic === ''">
				<div class="reelect-img" style="position: relative" @click="reelect">
					重新选择
				</div>
			</div>
		</div>

		<cover
			ref="image"
			:upload-number="uploadNumber"
			v-show="isReelect === true"
			:uploadPath="uploadPath"
			@success="onSuccess"
		></cover>
	</div>
</template>

<script>
import Cover from './cover'
export default {
	props: {
		uploadPath: {
			default: 'slideShow',
			type: String
		},
		pic: {
			default: '',
			type: String
		},

		picArray: {
			default() {
				return []
			},
			type: Array
		},
		uploadNumber: {
			default: 1,
			type: Number
		}
	},
	name: 'editCover',
	components: { Cover },
	data() {
		return {
			isReelect: false,
			imageUrl: config.imageUrl
		}
	},
	methods: {
		onSuccess(path) {
			this.$emit('success', path)
		},
		reelect() {
			this.$emit('success', '')
			this.isReelect = true
			this.$refs.image.$el
				.getElementsByClassName('el-upload el-upload--picture-card')[0]
				.click()
		}
	}
}
</script>

<style>
.img {
	width: 200px;
	background-color: #fafafa;
	border: 1px solid #ebebeb;
	cursor: pointer;
	text-align: center;
	font-size: 26px;
	font-weight: 400;
	color: #e6e6e6;
	border-radius: 5px;
	position: relative;
}
.reelect-img {
	position: absolute;
	bottom: 0;
	height: 40px;
	width: 100%;
	line-height: 40px;
	background: rgba(0, 0, 0, 0.4);
	font-size: 12px;
}
</style>
